import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import Hero from 'components/hero';
import Contact from 'components/contact';

const Kontakt = ({ data }) => {
  const { heroImage, title } = data.kontaktJson;
  const {
    siteMetadata: {
      companyInfo: { email, phone },
    },
  } = data.site;
  return (
    <Layout>
      <Hero title={title} image={heroImage} />
      <Contact phone={phone} email={email} />
      <div style={{ height: '50vh' }} />
    </Layout>
  );
};

Kontakt.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Kontakt;

export const query = graphql`
  query ContactPageQuery {
    kontaktJson {
      title
      heroImage {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    site {
      siteMetadata {
        companyInfo {
          phone
          email
        }
      }
    }
  }
`;
