import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/title';
import Box from 'components/box';
import Img from 'gatsby-image';
import { Container, ImageContainer, TitleContainer } from './hero.css';

const Hero = ({ title, image }) => {
  return (
    <Container>
      <TitleContainer>
        <Box>
          <Title as="h2" size="large">
            {title}
          </Title>
        </Box>
      </TitleContainer>
      <ImageContainer>
        <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
      </ImageContainer>
    </Container>
  );
};

export default Hero;
