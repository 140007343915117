import styled from 'styled-components';
import { accent, secondaryFont } from 'constants/theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
`;

export const Item = styled.div`
  text-align: center;
  background-color: white;
  margin: 1rem 0;
  padding: 2rem;
`;

export const Title = styled.h3`
  font-family: ${secondaryFont};
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: 400;

  &:after {
    content: '';
    background-color: ${accent};
    height: 1px;
    display: block;
    width: 50px;
    text-align: center;
    margin: 1.6rem auto;
  }
`;

export const Info = styled.div`
  font-family: ${secondaryFont};
  font-size: 2rem;

  a {
    color: black;
  }
`;
