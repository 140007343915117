import React from 'react';
import { Container, Item, Title, Info } from './contact.css';

const Contact = ({ phone, email }) => {
  return (
    <Container>
      <Item>
        <Title>Telefon</Title>
        <Info>{phone}</Info>
      </Item>
      <Item>
        <Title>Adres email</Title>
        <Info>
          <a href={`mailto:${email}`}>{email}</a>
        </Info>
      </Item>
    </Container>
  );
};

export default Contact;
