import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: auto;
  min-height: 250px;

  ${MEDIA.MIN_TABLET`
    min-height: 500px;
  `}
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: -1;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    max-height: 300px;

    ${MEDIA.MIN_TABLET`
      max-height: 500px;
    `}
  }
`;

export const TitleContainer = styled.div`
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;
